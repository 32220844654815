import React, { useMemo, useState, useContext, useEffect, useCallback } from "react";
import { useMachine, useSelector } from "@xstate/react";
import { Box, HStack, useToast } from "@chakra-ui/react";

import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { IPlayerSeasonArsenalScoresSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
import { THROWS_S } from "_react/shared/data_models/player/_constants";
import {
	BATS_OVERALL,
	THROWS_OVERALL,
	THROWS_L,
	THROWS_R,
	PLAYING_LEVEL_INTL
} from "_react/shared/data_models/seasonal_grades/_constants";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";
import Frog from "_react/shared/ui/icons/Frog";

import { BatterHandednessToggleOptions, PitcherHandednessToggleOptions, INTL } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TOGGLE_STYLE, ContentPaddingTopBreakpointValue } from "_react/playerpage/_styles";
import ArsenalTrendsSection from "_react/playerpage/shared/content/observed/ArsenalTrendsSection";
import HittingSection from "_react/playerpage/shared/content/observed/HittingSection";
import LocationsSection from "_react/playerpage/shared/content/observed/LocationsSection";
import StuffSection from "_react/playerpage/shared/content/observed/StuffSection";
import { TPlayerPageIntlState } from "_react/playerpage/intl/_machine";
import { PlayerPageIntlContext } from "_react/playerpage/intl/PlayerPageIntlProvider";
import { getIsTwoWayPlayer, getObservedIntlSeason } from "_react/playerpage/intl/shared/_helpers";
import { BaseballBatIconStyle, BaseballIconStyle } from "_react/playerpage/intl/shared/_styles";
import { $TSFixMeEval } from "_react/evals/shared/_types";

import ObservedTabMachine, {
	TObservedTabContext,
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_PLAYER_ID,
	SET_SEASON_FILTER
} from "_react/playerpage/intl/content/observed/_machine";
import { TObservedTabData } from "_react/playerpage/intl/content/observed/_types";

type TObservedTabProps = {
	player: TPlayerPageCombinedPlayer;
	evals: $TSFixMeEval[] | null | undefined;
	isSelected?: boolean;
	data?: TObservedTabData;
	shouldFetchData?: boolean;
};

const ObservedTab = ({ player, evals, isSelected = true, data, shouldFetchData = true }: TObservedTabProps) => {
	const toast = useToast();
	const [current, send] = useMachine(ObservedTabMachine(player?.id, shouldFetchData, data, toast));
	const { arsenalScoresThreshold, playerSeasonArsenalScores, seasonFilter } = current.context as TObservedTabContext;
	const throws = player?.intlProfile?.throws ?? player?.throws;

	const isFetchingArsenalScoresThreshold = shouldFetchData
		? current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD)
		: data?.isLoading;
	const isFetchingPlayerSeasonArsenalScores = shouldFetchData
		? current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES)
		: data?.isLoading;

	// Toggles for handedness
	const [batsFilter, setBatsFilter] = useState<string>(BATS_OVERALL);
	const [throwsFilter, setThrowsFilter] = useState<string>(THROWS_OVERALL);

	const playerPageIntlContext = useContext(PlayerPageIntlContext);
	const intlProspectValues: Array<IIntlProspectValue> | undefined = useSelector(
		playerPageIntlContext.playerPageIntlService,
		(state: TPlayerPageIntlState) => state.context.intlProspectValues
	);

	const isTwoWayPlayer = useMemo(() => getIsTwoWayPlayer(intlProspectValues, evals), [intlProspectValues, evals]);

	const [playerType, setPlayerType] = useState(player.isPitcher === 1 ? PLAYER_TYPE_PITCHER : PLAYER_TYPE_BATTER);
	const isSwitchPitcher = Boolean(throws && throws === THROWS_S && playerType === PLAYER_TYPE_PITCHER);
	const [pitcherThrowsFilter, setPitcherThrowsFilter] = useState<string | undefined>(
		isSwitchPitcher ? THROWS_L : undefined
	);

	const setPlayerTypeToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPlayerType(playerType);
	};

	const setPitcherThrowsToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPitcherThrowsFilter(playerType);
	};

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: player?.id });
	}, [player?.id, send]);
	useEffect(() => {
		if (data?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: data?.playerSeasonArsenalScores });
		}
	}, [data?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchData, send]);
	useEffect(() => {
		if (data?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: data?.arsenalScoresThreshold });
		}
	}, [data?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchData, send]);

	const currentSeason: number | undefined = getObservedIntlSeason(player);

	const setSeasonFilter = useCallback(
		(seasonFilter: number | undefined) => {
			if (seasonFilter !== undefined) send({ type: SET_SEASON_FILTER, data: seasonFilter });
		},
		[send]
	);

	useEffect(() => {
		setSeasonFilter(currentSeason);
	}, [currentSeason, setSeasonFilter]);

	const isFetchingCurrentSeason: boolean | undefined = useSelector(
		playerPageIntlContext.playerPageIntlService,
		(state: TPlayerPageIntlState) => state.context.currentSeasonData?.isFetching
	);

	// Season options should be the current season, current season - 1 and current season - 2
	const seasonOptions = useMemo(() => {
		if (isFetchingCurrentSeason) {
			return [{ label: "Current Season", value: 0 }];
		}
		if (currentSeason) {
			const offsets = [0, 1, 2];
			return offsets.map(offset => {
				const season = currentSeason - offset;
				return { label: `${season}`, value: season };
			});
		}
		return [];
	}, [isFetchingCurrentSeason, currentSeason]);

	// Get pitch types from playerSeasonArsenalScores to display in the ArsenalTrendsSection
	const pitchTypes: TPitchTypes[] | undefined = useMemo(
		() =>
			playerSeasonArsenalScores?.reduce((acc: Array<TPitchTypes>, curr: IPlayerSeasonArsenalScoresSchema) => {
				const pitchType = curr.pitchType as TPitchTypes;
				if (PITCH_TYPES.includes(pitchType) && !acc.includes(pitchType))
					acc.push(curr.pitchType as TPitchTypes);
				return acc;
			}, []),
		[playerSeasonArsenalScores]
	);

	return (
		<Box position="relative" top={"-8"}>
			<HStack
				top={{ base: undefined, md: "4px" }}
				position={{ base: undefined, md: "sticky" }}
				justifyContent={{ base: "start", md: "end" }}
				gap={{ base: "2", sm: "5" }}
				// This is in place due to issues we've seen with UI libraries overlaying this floating button (Highcharts). Ok to remove if necessary, as long as you can confirm content isn't above this (unless it is designed to be)
				zIndex={1}
			>
				{isSwitchPitcher && (
					<ToggleButton
						toggleOptions={[
							{
								value: THROWS_L,
								label: "L",
								icon:
									pitcherThrowsFilter === THROWS_L ? (
										<Frog sx={{ color: "gray.500", marginRight: "1" }} />
									) : (
										undefined
									)
							},
							{
								value: THROWS_R,
								label: "R",
								icon:
									pitcherThrowsFilter === THROWS_R ? (
										<Frog sx={{ color: "gray.500", marginRight: "1" }} />
									) : (
										undefined
									)
							}
						]}
						value={pitcherThrowsFilter}
						onSelect={setPitcherThrowsToggle}
						style={TOGGLE_STYLE}
					/>
				)}
				{isTwoWayPlayer && (
					<ToggleButton
						toggleOptions={[
							{
								value: PLAYER_TYPE_BATTER,
								label: "Position Player",
								icon: <BaseballBat sx={BaseballBatIconStyle} />
							},
							{
								value: PLAYER_TYPE_PITCHER,
								label: "Pitcher",
								icon: <Baseball sx={BaseballIconStyle} />
							}
						]}
						value={playerType}
						onSelect={setPlayerTypeToggle}
						style={TOGGLE_STYLE}
					/>
				)}
				<ToggleButton
					toggleOptions={seasonOptions}
					value={seasonFilter}
					onSelect={(value: string | number) =>
						typeof value === "string" ? setSeasonFilter(parseInt(value)) : setSeasonFilter(value)
					}
					isLoading={isFetchingCurrentSeason}
					style={TOGGLE_STYLE}
				/>
				{playerType === PLAYER_TYPE_BATTER && (
					<ToggleButton
						toggleOptions={PitcherHandednessToggleOptions}
						value={throwsFilter}
						onSelect={(value: string | number) =>
							typeof value === "number" ? setThrowsFilter(value.toString()) : setThrowsFilter(value)
						}
						style={TOGGLE_STYLE}
					/>
				)}
				{playerType === PLAYER_TYPE_PITCHER && (
					<ToggleButton
						toggleOptions={BatterHandednessToggleOptions}
						value={batsFilter}
						onSelect={(value: string | number) =>
							typeof value === "number" ? setBatsFilter(value.toString()) : setBatsFilter(value)
						}
						style={TOGGLE_STYLE}
					/>
				)}
			</HStack>
			<Box paddingTop={ContentPaddingTopBreakpointValue}>
				{playerType === PLAYER_TYPE_PITCHER && seasonFilter && (
					<>
						<StuffSection
							player={player}
							playingLevel={PLAYING_LEVEL_INTL}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							throwsFilter={pitcherThrowsFilter}
							shouldFetchArsenalStuffDistributionsSecondaryData={false}
							arsenalStuffDistributionsSecondaryData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								arsenalStuffDistributionsPlayer: {
									throws: player.throws
								},
								isLoading: isFetchingArsenalScoresThreshold
							}}
						/>
						{/* Not rendering the Locations section when other tabs are selected due to the IV heatmaps */}
						{isSelected && (
							<LocationsSection
								player={player}
								seasonFilter={seasonFilter}
								batsFilter={batsFilter}
								throwsFilter={pitcherThrowsFilter}
								useCountSplits={false}
								playingLevel={PLAYING_LEVEL_INTL}
								shouldFetchArsenalLocationTilesSecondaryData={false}
								arsenalLocationTilesSecondaryData={{
									arsenalScoresThreshold: arsenalScoresThreshold,
									arsenalLocationTilesPlayer: {
										throws: player.throws
									},
									isLoading: isFetchingArsenalScoresThreshold
								}}
							/>
						)}
						<ArsenalTrendsSection
							player={player}
							playerClassification={INTL}
							playingLevel={PLAYING_LEVEL_INTL}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							throwsFilter={pitcherThrowsFilter}
							shouldFetchPlayerPitchTypeLabelsData={false}
							playerPitchTypeLabelsData={{
								pitchTypes: pitchTypes,
								isLoading: isFetchingPlayerSeasonArsenalScores
							}}
							shouldFetchPitcherMetricOverTimeSecondaryPlotData={false}
							pitcherMetricOverTimeSecondaryPlotData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								playerSeasonArsenalScores: playerSeasonArsenalScores,
								isLoading: isFetchingArsenalScoresThreshold || isFetchingPlayerSeasonArsenalScores
							}}
							shouldFetchPitcherMetricRidgelineSecondaryPlotData={false}
							pitcherMetricRidgelineSecondaryPlotData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								playerSeasonArsenalScores: playerSeasonArsenalScores,
								isLoading: isFetchingArsenalScoresThreshold || isFetchingPlayerSeasonArsenalScores
							}}
							shouldFetchPitcherMetricContourSecondaryPlotData={false}
							pitcherMetricContourSecondaryPlotData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								playerSeasonArsenalScores: playerSeasonArsenalScores,
								playerHeight: { playerId: player.id, height: player.height },
								isLoading: isFetchingArsenalScoresThreshold || isFetchingPlayerSeasonArsenalScores
							}}
						/>
					</>
				)}
				{playerType === PLAYER_TYPE_BATTER && seasonFilter && (
					<>
						<HittingSection
							player={player}
							playingLevel={PLAYING_LEVEL_INTL}
							seasonFilter={seasonFilter}
							throwsFilter={throwsFilter}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};

export default ObservedTab;
