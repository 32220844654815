import React from "react";
import dayjs from "dayjs";

import { TOption } from "_react/inputs";
import { TList } from "_react/list/shared/_types";
import { IPlayerBasic } from "_react/shared/data_models/player/_types";
import { round10 } from "_react/shared/_helpers/numbers";
import { transaction } from "_react/shared/_types/mesa/transaction";
import { utcToDayjs } from "utils/_helpers";

import { TListExpandedPlayerPlanGoal, TListExpandedPlayerPlanTarget } from "_react/playerplan/list/_types";
import { FOCUS_AREA_TIER, PHP_TIER } from "_react/playerplan/metrics/_constants";
import {
	NULL_TEXT,
	PERCENTAGE_FORMAT,
	THREE_DECIMAL_FORMAT,
	POUNDS_FORMAT,
	NEWTONS_FORMAT,
	INCHES_FORMAT,
	SECONDS_FORMAT,
	DEGREES_FORMAT,
	W_FORMAT,
	M_S_FORMAT,
	IN_PROGRESS,
	LABEL_20_80,
	VERTICAL_BAR_BOTTOM,
	VERTICAL_BAR_TOP,
	AXIS_RANGE_20_80,
	TICK0_20_80,
	TICKFORMAT_THREE_DECIMAL,
	DTICK_20_80,
	PD_LIST_ORDER,
	HIT,
	HIT_OVERLAY_LABEL,
	PITCH_OVERLAY_LABEL,
	NEW_FRAMEWORK_YEAR_2023
} from "_react/playerplan/shared/_constants";
import {
	TPlayerPlanGoal,
	TPlayerPlanMetricValue,
	TPlayerPlanTarget,
	TPlayerPlanNote,
	TPlayerPlanGoalSplit,
	TPlayerPlanMetricScale,
	TOverlayMockGoal,
	TPlayerPlanMetricScaleLabel,
	TPlayerAppearance,
	TPlayerAbbreviated,
	TPlayerPlanQuantitativeTarget,
	TGoalForm,
	TListChangeFilters,
	TChange,
	TPlayerPlanDrill,
	TPlayerPlanGoalDrill,
	TPlayerPlanGoalDrillLabeled,
	TFocusAreaOption,
	TGoalOption
} from "_react/playerplan/shared/_types";
import { LinkHover } from "_react/playerplan/shared/_styles";

export const formatValue = (value: number, format?: string | null) => {
	if (!format) return `${+value.toFixed(1)}`; // Rounds value to at most 1 decimal place

	switch (format) {
		case PERCENTAGE_FORMAT:
			return `${(value * 100).toFixed(1)}%`;
		case THREE_DECIMAL_FORMAT:
			return `.${(value * 1000).toFixed(0)}`;
		case POUNDS_FORMAT:
			return `${value} LBS`;
		case NEWTONS_FORMAT:
			return `${value} N`;
		case INCHES_FORMAT:
			return `${value} in.`;
		case SECONDS_FORMAT:
			return `${value} sec.`;
		case DEGREES_FORMAT:
			return `${value} deg.`;
		case W_FORMAT:
			return `${value} W`;
		case M_S_FORMAT:
			return `${value} M/S`;
		default:
			return `${+value.toFixed(1)}`; // Rounds value to at most 1 decimal place
	}
};

export const listSortFunc = (a: TList, b: TList) => {
	const aValue = PD_LIST_ORDER[a.id];
	const bValue = PD_LIST_ORDER[b.id];
	if (aValue && bValue) return aValue - bValue;
	else if (aValue && !bValue) return -1;
	else if (bValue && !aValue) return 1;
	return a.name.localeCompare(b.name);
};

export const isValidFormat = (value: string | null, format: string | null): boolean => {
	// TODO: Add input validation for other format types, if it becomes necessary
	if (format === PERCENTAGE_FORMAT) return value !== null && parseFloat(value) >= 0 && parseFloat(value) <= 1;
	return true;
};

export const getActiveTargets = (
	playerPlanTargets?: Array<TPlayerPlanTarget>
): Array<TPlayerPlanTarget> | undefined => {
	return playerPlanTargets?.filter((t: TPlayerPlanTarget) => t.isActive);
};

export const getMostRecentActiveTarget = (
	playerPlanTargets?: Array<TPlayerPlanTarget>
): TPlayerPlanTarget | undefined => {
	return getActiveTargets(playerPlanTargets)?.sort((a, b) => (b.season ?? 0) - (a.season ?? 0))[0];
};

export const getSelectedSeasonActiveTarget = (
	season: number,
	playerPlanTargets?: Array<TPlayerPlanTarget>
): TPlayerPlanTarget | undefined => {
	return playerPlanTargets?.find((t: TPlayerPlanTarget) => t.season === season && t.isActive);
};

export const createGoalStatement = (
	metricLabel?: string,
	metricSubtypeLabel?: string,
	splits?: Array<TPlayerPlanGoalSplit>,
	direction?: string,
	value?: number | null,
	format?: string | null,
	isPHP = false,
	metricTypeAbbreviation?: string | null,
	isStrength = false,
	suffix?: string,
	season?: number | null
) => {
	const filteredSplits = splits?.filter((goalSplit: TPlayerPlanGoalSplit) => !goalSplit.isDeleted);

	// Handle PHP Goal
	if (isPHP) {
		return `${metricTypeAbbreviation ? `${metricTypeAbbreviation} - ` : ""}${metricLabel ?? "Unlabeled PHP Goal"}`;
	}

	// 1. If strength, include metric type abbreviation
	// 2. Include metric subtype if it exists
	// 3. If direction, include it, otherwise say "Improve"
	// 4. If value, display preposition (to/from/at) + value
	// 5. If splits, display splits
	// 6. If suffix, include suffix
	return `${metricTypeAbbreviation && isStrength ? `${metricTypeAbbreviation} - ` : ""}${
		metricSubtypeLabel ? `(${metricSubtypeLabel}) ` : ""
	}${direction ?? "Improve"} ${season ? `${season} ` : ""}${metricLabel}${
		value?.toString() ? (direction === "Maintain" ? " at " : " to ") : ""
	}${value?.toString() ? formatValue(value, format) : ""}${
		filteredSplits?.length
			? ` (${filteredSplits
					.map((goalSplit: TPlayerPlanGoalSplit) => goalSplit.playerPlanSplit?.label)
					.join(", ")})`
			: ""
	}${suffix ?? ""}`;
};

// Helper function to add the date range to the goal statement
export const createGoalDateStatement = (startDate?: string | null, endDate?: string | null) => {
	return startDate || endDate
		? ` (${startDate ? `${endDate ? "" : "start "}${dayjs(startDate).format("MM/DD")}` : ""}${
				endDate ? `${startDate ? "-" : "end "}${dayjs(endDate).format("MM/DD")}` : ""
		  })`
		: "";
};

// Helper functions to validate the Add Goal Modal Form
export const isPrimaryGoalValid = (addGoalForm: TGoalForm, isPHP: boolean) => {
	// 1. Must have a metric specified
	if (!addGoalForm.metric?.toString()) return false;
	// 1a. The above is the only criteria for a PHP goal, so at this point, return true if PHP.
	else if (isPHP) return true;
	// 2. If metric is quantitative, the value must exist
	else if (!addGoalForm.isQualitative && !addGoalForm.target) return false;
	// 3. If a value exists, the value must have a valid format
	else if (addGoalForm.target && !isValidFormat(addGoalForm.target, addGoalForm.metricFormat)) return false;
	// 4. If a metric is quantitative or a value exists, direction must exist
	else if ((!addGoalForm.isQualitative || addGoalForm.target) && !addGoalForm.direction) return false;
	// 5. Season must exist
	else if (!addGoalForm.season) return false;

	return true;
};

export const isOnlySecondaryGoalValid = (addGoalForm: TGoalForm, isPHP: boolean) => {
	// 1. Must have a metric specified
	if (!addGoalForm.metric?.toString()) return false;
	// 1a. The above is the only criteria for a PHP goal, so at this point, return true if PHP.
	else if (isPHP) return true;
	// 2. If metric is quantitative, the value must exist
	else if (!addGoalForm.isQualitative && !addGoalForm.target) return false;
	// 3. If a value exists, the value must have a valid format
	else if (addGoalForm.target && !isValidFormat(addGoalForm.target, addGoalForm.metricFormat)) return false;
	// 4. If a metric is quantitative or a value exists, direction must exist
	else if ((!addGoalForm.isQualitative || addGoalForm.target) && !addGoalForm.direction) return false;

	return true;
};

export const isSecondaryGoalValid = (addGoalForm: TGoalForm) => {
	// 1. Must have a metric specified
	if (!addGoalForm.secondaryGoal?.metric?.toString()) return false;
	// 2. If metric is quantitative, the value must exist
	else if (!addGoalForm.secondaryGoal?.isQualitative && !addGoalForm.secondaryGoal?.target) return false;
	// 3. If a value exists, the value must have a valid format
	else if (
		addGoalForm.secondaryGoal?.target &&
		!isValidFormat(addGoalForm.secondaryGoal?.target, addGoalForm.secondaryGoal?.metricFormat)
	)
		return false;
	// 4. If a metric is quantitative or a value exists, direction must exist
	else if (
		(!addGoalForm.secondaryGoal?.isQualitative || addGoalForm.secondaryGoal?.target) &&
		!addGoalForm.secondaryGoal?.direction
	)
		return false;

	return true;
};

export const getPlayerAbbreviatedName = (player: TPlayerAbbreviated) => {
	return player ? `${player?.firstName ?? player?.firstNameLegal} ${player?.lastName}` : "";
};

export const getDrillLabel = (name: string, typeLabel: string, subtypeLabel?: string): string => {
	return `${name} (${typeLabel}${subtypeLabel ? ` - ${subtypeLabel}` : ""})`;
};

export const getMetricLabel = (tier: string, label: string, typeLabel: string, subtypeLabel?: string): string => {
	return `${tier}${tier ? ": " : ""}${label} (${typeLabel})${subtypeLabel ? ` (${subtypeLabel})` : ""}`;
};

export const getPlayerPageLink = (player: TPlayerAbbreviated): JSX.Element => {
	return (
		<LinkHover
			href={`/player?philId=${player.playerProId}&view=plan&viewClassification=pro`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{getPlayerAbbreviatedName(player)}
		</LinkHover>
	);
};

export const getAssignedCellValue = (players: Array<TPlayerAbbreviated> | undefined) => {
	if (!players || players.length === 0) return "0 players";
	if (players.length === 1) return getPlayerPageLink(players[0]);
	if (players.length === 2)
		return (
			<>
				{getPlayerPageLink(players[0])} and<br></br>
				{getPlayerPageLink(players[1])}
			</>
		);
	return <>{getPlayerPageLink(players[0])} and</>;
};

export const getPlayerName = (player: IPlayerBasic | null) => {
	return player ? `${player?.firstName ?? player?.firstNameLegal} ${player?.lastName}` : "";
};

export const getPlayerOrgTeam = (player: IPlayerBasic | null) => {
	return player?.proProfile && (player?.proProfile?.orgCode || player?.proProfile?.teamName)
		? `(${player?.proProfile?.orgCode ?? NULL_TEXT}) ${player?.proProfile?.teamName ?? NULL_TEXT}`
		: `${NULL_TEXT}`;
};

export const decimalToPercentage = (num?: number | null, toFixed = 1) => {
	if (num == null) return "-";
	return `${(num * 100).toFixed(toFixed)}%`;
};

export const sortGoals = (
	a: TPlayerPlanGoal | TListExpandedPlayerPlanGoal,
	b: TPlayerPlanGoal | TListExpandedPlayerPlanGoal
) => {
	// Sort by priority
	if (a.isPriority && !b.isPriority) return -1;
	if (!a.isPriority && b.isPriority) return 1;
	// Sort by rank
	if (!a.rank?.toString() && !b.rank?.toString()) return 0;
	if (!a.rank?.toString()) return 1;
	if (!b.rank?.toString()) return -1;
	return a.rank - b.rank;
};

export const getDefaultListChangeFilters = (): TListChangeFilters => {
	return {
		fromDate: dayjs()
			.subtract(7, "day")
			.format("YYYY-MM-DD"),
		toDate: dayjs().format("YYYY-MM-DD"),
		playerName: null,
		teamLevel: null,
		metricType: null,
		changeType: null
	};
};

// Recent Change Table Cell Value Functions
export const getDepartmentCellValue = (c: TChange): string =>
	`${c.metricTypeAbbreviation ?? ""}${c.metricSubtypeLabel ? ` (${c.metricSubtypeLabel})` : ""}`;

export const getGoalCellValue = (c: TChange): string =>
	c.metricLabel
		? createGoalStatement(
				c.metricLabel,
				undefined,
				undefined,
				c.targetDirectionLabel,
				c.targetValue,
				c.metricFormat,
				c.metricTierLk === 0
		  )
		: "";

export const getFocusAreaCellValue = (c: TChange): string =>
	`${c.focusAreaLabel && c.focusAreaTypeAbbreviation ? `${c.focusAreaTypeAbbreviation} - ${c.focusAreaLabel}` : ""}`;

export const getSupportingActionCellValue = (c: TChange): string =>
	`${
		c.drillLabel
			? `${c.drillLabel}${c.drillSubtypeLabel ? ` (${c.drillSubtypeLabel})` : ""}`
			: `${c.content?.substring(0, 30) ?? ""}${(c.content?.length ?? 0) > 30 ? "..." : ""}`
	}`;

export const getChangeDateCellValue = (c: TChange): string =>
	`${
		c.date
			? dayjs
					.utc(c.date.substring(0, 23))
					.local()
					.format("MM/DD/YY")
			: ""
	}`;

export const getChangeDateCellSortValue = (c: TChange): string =>
	`${
		c.date
			? dayjs
					.utc(c.date.substring(0, 23))
					.local()
					.format("YYYY-MM-DD")
			: ""
	}`;

// Notes helpers
export const getGoalStatementFromNote = (
	note: TPlayerPlanNote,
	// When true, filters out inactive goals
	isActiveOnly = false
): string => {
	const goal = note.playerPlanGoal?.parentGoal ?? note.playerPlanGoal;
	if (!goal || (isActiveOnly && goal.status.value !== IN_PROGRESS)) return "";

	const activeTarget =
		getSelectedSeasonActiveTarget(dayjs(note.createDate).year(), goal?.playerPlanTargets) ??
		getMostRecentActiveTarget(goal?.playerPlanTargets);
	const isPHP = goal?.playerPlanMetric?.metricTierLk === PHP_TIER;

	return createGoalStatement(
		goal.playerPlanMetric?.label,
		goal.playerPlanMetric?.metricSubtype?.label,
		goal.playerPlanSplits,
		activeTarget?.direction?.label,
		activeTarget?.value,
		goal.playerPlanMetric?.format,
		isPHP,
		goal.playerPlanMetric?.metricType.abbreviation,
		false
	);
};

export const getFocusAreaLabelFromNote = (note: TPlayerPlanNote): string => {
	const focusArea: TPlayerPlanGoal | null = note.playerPlanGoal?.parentGoal ? note.playerPlanGoal : null;
	if (!focusArea || focusArea.playerPlanMetric.metricTierLk !== FOCUS_AREA_TIER) return "";

	return `${focusArea.playerPlanMetric.metricType.abbreviation} - ${focusArea.playerPlanMetric.label}`;
};

export const getDrillLabelFromNote = (note: TPlayerPlanNote): string => {
	const drill: TPlayerPlanDrill | null | undefined = note.playerPlanDrill;
	return drill ? `${drill.name}${drill.metricSubtype ? ` (${drill.metricSubtype.label})` : ""}` : "";
};

// Given a list of notes, get the user ids who have created notes
export const getNoteCreatorFilterIds = (notes: TPlayerPlanNote[] | undefined): Array<number> | undefined => {
	return notes
		? [
				...new Set(
					notes.reduce((noteCreatorIds: Array<number>, note: TPlayerPlanNote) => {
						if (note.lastChangeUserId !== null) noteCreatorIds.push(note.lastChangeUserId);
						return noteCreatorIds;
					}, [])
				)
		  ]
		: undefined;
};

// Given a list of notes, get the options used for the department filter
export const getDepartmentFilterOptions = (
	notes: TPlayerPlanNote[] | undefined
): Array<TOption<string>> | undefined => {
	return notes
		?.reduce((departmentFilterOptions: Array<TOption<string>>, note: TPlayerPlanNote) => {
			if (
				// Ensure value is not undefined
				note.metricType?.value &&
				// Ensure value does not already exist in our accumulator array
				!departmentFilterOptions.some(
					(departmentFilterOption: TOption<string>) => departmentFilterOption.value === note.metricType?.value
				)
			) {
				departmentFilterOptions.push({
					label: note.metricType.label,
					value: note.metricType.value
				});
			}
			return departmentFilterOptions;
		}, [])
		?.sort((a: TOption<string>, b: TOption<string>): number => a.label.localeCompare(b.label));
};

// Given a list of notes, get the goal statement options for the goal filter
export const getGoalFilterOptions = (notes: TPlayerPlanNote[] | undefined): Array<TOption<string>> | undefined => {
	return notes
		? [
				...new Set(
					notes.map((note: TPlayerPlanNote) => {
						return getGoalStatementFromNote(note, true);
					})
				)
		  ]
				.reduce((goalLabels: Array<TOption<string>>, goalLabel: string) => {
					if (goalLabel !== "") goalLabels.push({ label: goalLabel, value: goalLabel });
					return goalLabels;
				}, [])
				.sort((a: TOption<string>, b: TOption<string>): number => a.label.localeCompare(b.label))
		: undefined;
};

// Given a list of notes, get the focus area labels for the focus area filter
export const getFocusAreaFilterOptions = (notes: TPlayerPlanNote[] | undefined): Array<TOption<string>> | undefined => {
	return notes
		? [
				...new Set(
					notes.map((note: TPlayerPlanNote) => {
						return getFocusAreaLabelFromNote(note);
					})
				)
		  ]
				.reduce((focusAreaLabels: Array<TOption<string>>, focusAreaLabel: string) => {
					if (focusAreaLabel !== "") focusAreaLabels.push({ label: focusAreaLabel, value: focusAreaLabel });
					return focusAreaLabels;
				}, [])
				.sort((a: TOption<string>, b: TOption<string>): number => a.label.localeCompare(b.label))
		: undefined;
};

// Given a list of notes, get the drill labels for the drill filter
export const getDrillFilterOptions = (notes: TPlayerPlanNote[] | undefined): Array<TOption<string>> | undefined => {
	return notes
		? [
				...new Set(
					notes.map((note: TPlayerPlanNote) => {
						return getDrillLabelFromNote(note);
					})
				)
		  ]
				.reduce((drillLabels: Array<TOption<string>>, drillLabel: string) => {
					if (drillLabel !== "") drillLabels.push({ label: drillLabel, value: drillLabel });
					return drillLabels;
				}, [])
				.sort((a: TOption<string>, b: TOption<string>): number => a.label.localeCompare(b.label))
		: undefined;
};

// Given a list of all of a player's goals, get the goal options to be used in the Note creation dropdown
export const getGoalOptions = (goals: TPlayerPlanGoal[] | null): Array<TGoalOption> | undefined => {
	return goals
		?.reduce((goalOptions: Array<TGoalOption>, goal: TPlayerPlanGoal) => {
			// filter goals down to only the set of goals on which you can leave notes
			if (goal.status.value === IN_PROGRESS && goal.parentGoalId == null && goal.primaryGoalId == null) {
				const activeTarget =
					getSelectedSeasonActiveTarget(dayjs().year(), goal?.playerPlanTargets) ??
					getMostRecentActiveTarget(goal?.playerPlanTargets);
				const isPHP = goal?.playerPlanMetric?.metricTierLk === PHP_TIER;
				goalOptions.push({
					label: createGoalStatement(
						goal.playerPlanMetric?.label,
						goal.playerPlanMetric?.metricSubtype?.label,
						goal.playerPlanSplits,
						activeTarget?.direction?.label,
						activeTarget?.value,
						goal.playerPlanMetric?.format,
						isPHP,
						goal.playerPlanMetric?.metricType.abbreviation,
						false
					),
					value: goal.id,
					goalFocusAreas: goals?.filter((focusArea: TPlayerPlanGoal) => {
						return (
							focusArea.parentGoalId === goal.id &&
							focusArea.playerPlanMetric.metricTierLk === FOCUS_AREA_TIER
						);
					})
				});
			}
			return goalOptions;
		}, [])
		.sort((a: TGoalOption, b: TGoalOption): number => a.label.localeCompare(b.label));
};

export const getFocusAreaOptions = (focusAreas: TPlayerPlanGoal[] | null): Array<TFocusAreaOption> | undefined => {
	return focusAreas
		? focusAreas.map((focusArea: TPlayerPlanGoal) => {
				return {
					label: `${focusArea.playerPlanMetric.metricType.abbreviation} - ${focusArea.playerPlanMetric.label}`,
					value: focusArea.id,
					goalDrills: focusArea.playerPlanDrills
				};
		  })
		: undefined;
};

export const getDrillOptions = (
	playerPlanGoalDrills: Array<TPlayerPlanGoalDrill> | null
): Array<TOption<number>> | undefined => {
	if (!playerPlanGoalDrills) return undefined;

	const labeledGoalDrills = playerPlanGoalDrills.filter(
		(goalDrill: TPlayerPlanGoalDrill) => goalDrill.playerPlanDrillId != null && goalDrill.playerPlanDrill != null
	) as Array<TPlayerPlanGoalDrillLabeled>;

	return labeledGoalDrills.map((labeledGoalDrill: TPlayerPlanGoalDrillLabeled) => {
		const drill = labeledGoalDrill.playerPlanDrill;
		return {
			label: `${drill.name}${drill.metricSubtype ? ` (${drill.metricSubtype.label})` : ""}`,
			value: labeledGoalDrill.playerPlanDrillId
		};
	});
};

//
// GOAL GRAPHS HELPERS
//

// Decides whether we are using the 20-80 scale or another scale for overlay metrics, returns undefined otherwise
// Assumes that all overlay metrics have the same scale properties to generate graph, and that all metric scales have the same label
export const getOverlayScaleLabel = (overlayMockGoals: TOverlayMockGoal[] | undefined): TPlayerPlanMetricScaleLabel => {
	if (!overlayMockGoals?.length) return undefined;
	return overlayMockGoals[0].shouldScaleOverlay
		? overlayMockGoals[0].metricScale
			? overlayMockGoals[0].metricScale[0].label
			: undefined
		: undefined;
};

// Decides whether we are using the 20-80 scale or another scale for the goal metric, returns undefined otherwise
// Assumes that all metric scales have the same label
export const getScaleLabel = (goal: TPlayerPlanGoal): TPlayerPlanMetricScaleLabel => {
	return goal.playerPlanMetric.shouldScale && goal.metricScale ? goal.metricScale[0].label : undefined;
};

export const getScaledMetricValue = (
	value: number,
	scaleConstant: number,
	scaleCoefficient: number,
	scaleMean: number,
	scaleStd: number
): number => {
	return parseFloat(round10(scaleConstant + (scaleCoefficient * (value - scaleMean)) / scaleStd, 0) ?? "");
};

// Scales metrics based on scale, value, year
// Sets min and max value for 20-80
export const scaleMetrics = (
	scales: TPlayerPlanMetricScale[],
	value: number | null,
	endDate: dayjs.Dayjs
): number | null => {
	if (value === null) return value;

	// finds correct scale based on metric year
	const metricYear = endDate.year();
	const scale = scales.find(scale => {
		return scale.year === metricYear;
	});

	if (scale === undefined) return null;

	const returnValue = getScaledMetricValue(value, scale.constant, scale.coefficient, scale.mean, scale.std);

	if (scale.label === LABEL_20_80 && returnValue > 80) return 80;
	if (scale.label === LABEL_20_80 && returnValue < 20) return 20;
	return returnValue;
};

// get array of date strings from player appearances
export const getPlayerAppearanceDates = (appearances: TPlayerAppearance[] | undefined): string[] => {
	return appearances?.map(appearance => appearance.date) ?? [];
};

// get whether an appearance exists with a given date
export const isValidPlayerAppearance = (appearances: TPlayerAppearance[] | undefined, date: string): boolean => {
	if (appearances === undefined) return false;
	return (
		appearances.findIndex((appearance: TPlayerAppearance) => {
			if (appearance.date !== date) return false;
			return true;
		}) !== -1
	);
};

// get whether graph should display based on if there are any metric values
export const shouldDisplayGraph = (
	goal: TPlayerPlanGoal,
	secondaryGoal?: TPlayerPlanGoal,
	season?: number,
	endDate?: string | null
) => {
	return (
		getMostRecentMetricValue(
			goal?.playerPlanMetricValues,
			undefined,
			false,
			goal?.playerAppearances,
			true,
			season,
			endDate
		) != null ||
		getMostRecentMetricValue(
			secondaryGoal?.playerPlanMetricValues,
			undefined,
			false,
			goal?.playerAppearances,
			true,
			season,
			endDate
		) != null
	);
};

// Returns the set of seasons associated with a goal, sorted ascending
export const getGoalSeasons = (
	goal: TPlayerPlanGoal | TListExpandedPlayerPlanGoal | undefined
): Array<number> | null => {
	if (!goal) return null;
	const goalSeasons: Array<number> | undefined = goal.playerPlanTargets
		?.filter(
			(goalTarget: TPlayerPlanTarget | TListExpandedPlayerPlanTarget) => goalTarget.isActive && goalTarget.season
		)
		.map((goalTarget: TPlayerPlanTarget | TListExpandedPlayerPlanTarget) => goalTarget.season!)
		.sort((a: number, b: number) => a - b);

	// If no active targets, return create date season
	return !goalSeasons || goalSeasons.length === 0 ? [+goal.createDate.substring(0, 4)] : goalSeasons;
};

// Returns the options to be used for the Season Filter dropdown in the top-level PlayerPlan component
export const getGoalSeasonFilterOptions = (): Array<{ value: string; display: string }> => {
	const goalSeasonFilterOptions = [{ value: "", display: "All" }];

	// Allow users to select any season from the start of the new Manuels framework up until next year
	const numSeasons = dayjs().year() - NEW_FRAMEWORK_YEAR_2023 + 2;
	return [
		...goalSeasonFilterOptions,
		...Array.from(Array(numSeasons), (_, i: number) => {
			return {
				value: `${i + NEW_FRAMEWORK_YEAR_2023}`,
				display: `${i + NEW_FRAMEWORK_YEAR_2023}`
			};
		})
	];
};

// Get the season to display in the Goal Details season dropdown upon clicking on a goal
export const getInitialGoalDetailsSeason = (goal: TPlayerPlanGoal): number => {
	const goalSeasons: number[] | null = getGoalSeasons(goal);
	return goalSeasons
		? goalSeasons?.includes(dayjs().year())
			? dayjs().year()
			: Math.max(...goalSeasons)
		: dayjs().year();
};

// Returns a statement displaying the set of seasons associated with a goal
// For sets of consecutive seasons, displays start and end season separated with a hyphen
// Otherwise, displays comma separated seasons
// For example, [2022,2024,2025,2026] displays as "2022, 2024-2026"
// NOTE: sortedGoalSeasons must be sorted ascending
export const getGoalSeasonsStatement = (sortedGoalSeasons: Array<number> | null): string => {
	if (!sortedGoalSeasons?.length) return "";

	let output = "";
	let isConsecutive = false;

	sortedGoalSeasons?.forEach((season, index) => {
		if (index === 0) output += season;
		else if (sortedGoalSeasons[index - 1] === season - 1) isConsecutive = true;
		else {
			isConsecutive ? (output += `-${sortedGoalSeasons[index - 1]}, ${season}`) : (output += `, ${season}`);
			isConsecutive = false;
		}
	});

	return isConsecutive ? output + `-${sortedGoalSeasons[sortedGoalSeasons.length - 1]}` : output;
};

// Get the metric value associated with a player's most recent appearance
// Metric value must be non-null if one exists
export const getMostRecentMetricValue = (
	metricValues: TPlayerPlanMetricValue[] | undefined, // must be sorted descending
	metricScale: TPlayerPlanMetricScale[] | undefined,
	shouldScale: boolean,
	playerAppearances: TPlayerAppearance[] | undefined,
	isSeasonValue: boolean,
	season?: number,
	endDate?: string | null
): number | null => {
	const valueIndex = isSeasonValue ? "seasonValue" : "value";
	const metricValuesFiltered =
		endDate && metricValues
			? [...metricValues].filter(
					(metricValue: TPlayerPlanMetricValue) =>
						dayjs(metricValue.date).isBefore(dayjs(endDate), "day") ||
						dayjs(metricValue.date).isSame(dayjs(endDate), "day")
			  )
			: metricValues;
	const metricValue: TPlayerPlanMetricValue | null =
		metricValuesFiltered?.find((metricValue: TPlayerPlanMetricValue) => {
			return (
				metricValue[valueIndex] !== null &&
				// If season argument provided, ensure that metric value date matches season
				(!season || +metricValue.date.substring(0, 4) === season) &&
				isValidPlayerAppearance(playerAppearances, metricValue.date)
			);
		}) ?? null;

	if (metricValue === null) return metricValue;
	return shouldScale && metricScale
		? scaleMetrics(metricScale, metricValue[valueIndex], dayjs(metricValue.date))
		: metricValue[valueIndex];
};

// Get metric value associated with the previous season
export const getPreviousSeasonMetricValue = (
	metricValues: TPlayerPlanMetricValue[] | undefined, // must be sorted descending
	metricScale: TPlayerPlanMetricScale[] | undefined,
	shouldScale: boolean,
	season: number
) => {
	const metricValue: TPlayerPlanMetricValue | null =
		metricValues?.find((metricValue: TPlayerPlanMetricValue) => {
			return metricValue.date.substring(0, 4) === `${season - 1}`;
		}) ?? null;

	if (metricValue === null) return metricValue;
	return shouldScale && metricScale
		? scaleMetrics(metricScale, metricValue.seasonValue, dayjs(metricValue.date))
		: metricValue.seasonValue;
};

// Min date based on first appearance (or start date if that exists), fallback to start of selected season otherwise
export const getMinGoalChartDate = (
	season: number,
	firstAppearanceDate?: string,
	targetStartDate?: string | null
): dayjs.Dayjs => {
	if (targetStartDate) {
		if (firstAppearanceDate && dayjs(firstAppearanceDate).isAfter(targetStartDate)) {
			return dayjs(firstAppearanceDate);
		}
		return dayjs(targetStartDate);
	}
	if (firstAppearanceDate) return dayjs(firstAppearanceDate);
	return dayjs(`${season}-1-1`);
};

// Max date based on last appearance (or end date if that exists)
// If selected year matches this year, fallback to today
// Otherwise, fallback to end of selected season
export const getMaxGoalChartDate = (
	season: number,
	lastAppearanceDate?: string,
	targetEndDate?: string | null
): dayjs.Dayjs => {
	if (targetEndDate) {
		if (lastAppearanceDate && dayjs(lastAppearanceDate).isBefore(dayjs(targetEndDate)))
			return dayjs(lastAppearanceDate);
		return dayjs(targetEndDate);
	}
	if (lastAppearanceDate) return dayjs(lastAppearanceDate);
	return season === dayjs().year() ? dayjs().startOf("day") : dayjs(`${season}-12-31`);
};

// populate 3 arrays for goal metric or overlay mock goal to be passed to Plot component
export const getGoalMetricPlotData = (
	metricScale: TPlayerPlanMetricScale[] | undefined,
	metricValues: TPlayerPlanMetricValue[],
	shouldScale: boolean,
	splitLabel: string | null,
	label: string,
	rollingAveragePeriodDays: number,
	playerAppearances: TPlayerAppearance[] | undefined,
	isSeasonValue = false,
	metricFormat: string | null = null,
	minDate: dayjs.Dayjs,
	maxDate: dayjs.Dayjs
): Plotly.Data => {
	const xMetric: string[] = [];
	const yMetric: (number | null)[] = [];
	const metricText: string[] = [];

	if (metricValues && playerAppearances)
		metricValues
			.filter(
				(metricValue: TPlayerPlanMetricValue) =>
					isValidPlayerAppearance(playerAppearances, metricValue.date) &&
					(dayjs(metricValue.date).isBefore(maxDate, "day") ||
						dayjs(metricValue.date).isSame(maxDate, "day")) &&
					(dayjs(metricValue.date).isAfter(minDate, "day") || dayjs(metricValue.date).isSame(minDate, "day"))
			)
			.forEach((metricValue: TPlayerPlanMetricValue) => {
				const startDate = dayjs(metricValue.date)
					.subtract(rollingAveragePeriodDays, "day")
					.format("MM/DD/YY");
				const endDate = dayjs(metricValue.date);

				const scaledValue =
					shouldScale && metricScale
						? scaleMetrics(
								metricScale,
								isSeasonValue ? metricValue.seasonValue : metricValue.value,
								endDate
						  )
						: isSeasonValue
						? metricValue.seasonValue
						: metricValue.value;

				xMetric.push(metricValue.date);
				yMetric.push(scaledValue);
				metricText.push(
					`${label}: ${scaledValue ? formatValue(scaledValue, metricFormat) : scaledValue}<br>${
						splitLabel ? `${splitLabel}<br>` : ""
					}${
						rollingAveragePeriodDays > 0 && !isSeasonValue
							? `Rolling ${rollingAveragePeriodDays} Day Average<br>`
							: ""
					}${`${rollingAveragePeriodDays > 0 && !isSeasonValue ? `${startDate} - ` : ""}`}${endDate.format(
						"MM/DD/YY"
					)}${"<br>Click for Game Summary"}`
				);
			});
	return { x: xMetric, y: yMetric, text: metricText };
};

// populate 3 arrays for notes to be passed to Plot component
export const getNotesPlotData = (notes: TPlayerPlanNote[], minDate: dayjs.Dayjs): Plotly.Data => {
	const xNotes: (Date | null)[] = [];
	const yNotes: (number | null)[] = [];
	const noteText: string[] = [];
	if (notes)
		notes
			.sort((a: TPlayerPlanNote, b: TPlayerPlanNote) => {
				return a.createDate.localeCompare(b.createDate);
			})
			.forEach((note: TPlayerPlanNote, index: number) => {
				const noteDate = note.createDate ? utcToDayjs(note.createDate) : undefined;
				if (noteDate && !noteDate?.isBefore(minDate)) {
					let noteTextData = "";
					if (index === 0 || !noteDate.isSame(utcToDayjs(notes[index - 1]?.createDate))) {
						noteTextData = `Author: ${note.lastChangeUser?.firstName?.concat(" ") ?? ""}${note
							.lastChangeUser?.lastName ?? ""}<br>Date: ${noteDate.format("MM/DD/YY")}`;
					} else {
						noteTextData = `Multiple Notes<br>${noteDate.format("MM/DD/YY")}`;
						for (let i = 0; i < 3; i++) {
							noteText.pop();
							xNotes.pop();
							yNotes.pop();
						}
					}
					noteText.push(noteTextData, noteTextData, "");
					xNotes.push(noteDate.toDate(), noteDate.toDate(), null);
					yNotes.push(VERTICAL_BAR_BOTTOM, VERTICAL_BAR_TOP, null); // pins notes to top and bottom of graph
				}
			});
	return { x: xNotes, y: yNotes, text: noteText };
};

// populate 3 arrays for transactions to be passed to Plot component
export const getTransactionsPlotData = (
	transactions: transaction[] | null,
	minDate: dayjs.Dayjs,
	maxDate: dayjs.Dayjs,
	areas: string[],
	shouldExclude: boolean
): Plotly.Data => {
	const xTransaction: (Date | null)[] = [];
	const yTransaction: (number | null)[] = [];
	const transactionText: string[] = [];

	if (transactions)
		transactions.forEach((transaction: transaction) => {
			const transactionDate = dayjs(transaction.date);
			if (
				!dayjs(transaction.date).isBefore(minDate) &&
				!dayjs(transactionDate).isAfter(maxDate) &&
				((!shouldExclude && areas.includes(transaction.transaction_type ?? "")) ||
					(shouldExclude && !areas.includes(transaction.transaction_type ?? "")))
			) {
				let transactionTextData = `${transaction?.transaction_name ?? ""}<br>From: ${transaction?.from_team ??
					""}<br>To: ${transaction?.to_team ?? ""}<br>Date: ${transactionDate.format("MM/DD/YY")}`;

				if (transactionDate.isSame(dayjs(xTransaction[xTransaction.length - 2] ?? undefined))) {
					for (let i = 0; i < 3; i++) {
						xTransaction.pop();
						yTransaction.pop();
						transactionText.pop();
					}
					transactionTextData = `Multiple Transactions<br>Date: ${transactionDate.format("MM/DD/YY")}`;
				}
				xTransaction.push(transactionDate.toDate(), transactionDate.toDate(), null);
				yTransaction.push(VERTICAL_BAR_BOTTOM, VERTICAL_BAR_TOP, null); // pins transactions to top and bottom of graph
				transactionText.push(transactionTextData, transactionTextData, "");
			}
		});

	return { x: xTransaction, y: yTransaction, text: transactionText };
};

// helper function for getTargetPlotData() to populate 3 target arrays
export const pushToTargetArrays = (
	value: number,
	date: dayjs.Dayjs,
	xTarget: (Date | null)[],
	yTarget: (number | null)[],
	targetText: string[],
	isAppearance: boolean,
	metricLabel?: string
): void => {
	xTarget.push(date.toDate());
	yTarget.push(value);
	targetText.push(
		`${metricLabel ? `${metricLabel} ` : ""}Target: ${value}<br>${date.format("MM/DD/YY")}${
			isAppearance ? "<br>Click for Game Summary" : ""
		}`
	);
};

// Find active quantitative target (has a value and is active)
export const getActiveQuantitativeTarget = (
	targets: Array<TPlayerPlanTarget> | undefined,
	season: number
): TPlayerPlanTarget | undefined => {
	// Filter out any targets that don't have a value
	const filteredTargets: Array<TPlayerPlanQuantitativeTarget> | undefined = targets?.filter(
		(t: TPlayerPlanTarget) => {
			return t.value !== null;
		}
	) as Array<TPlayerPlanQuantitativeTarget>;

	// Find active target
	const activeTarget: TPlayerPlanQuantitativeTarget | undefined = filteredTargets?.find(
		(t: TPlayerPlanQuantitativeTarget) => t.isActive && t.season === season
	);

	return activeTarget;
};

// populate 3 arrays for target to be passed to Plot component
export const getTargetPlotData = (
	target: TPlayerPlanTarget | undefined,
	minDate: dayjs.Dayjs,
	maxDate: dayjs.Dayjs,
	playerAppearanceDates: string[],
	metricLabel?: string
): Plotly.Data | null => {
	const xTarget: (Date | null)[] = [];
	const yTarget: (number | null)[] = [];
	const targetText: string[] = [];

	// Show current target
	const targetValue = target?.value;

	if (!targetValue) return null;

	pushToTargetArrays(
		targetValue,
		minDate,
		xTarget,
		yTarget,
		targetText,
		playerAppearanceDates.includes(minDate.format("YYYY-MM-DD")),
		metricLabel
	);

	pushToTargetArrays(
		targetValue,
		maxDate,
		xTarget,
		yTarget,
		targetText,
		playerAppearanceDates.includes(maxDate.format("YYYY-MM-DD")),
		metricLabel
	);
	return { x: xTarget, y: yTarget, text: targetText };
};

// These functions get yAxis properties to be passed to goal graphs
// Gets axis range based on scale label

export const getLeftAxisLabel = (goal: TPlayerPlanGoal, secondaryGoal?: TPlayerPlanGoal): string => {
	// If primary goal has a metric scale and there is a secondary goal, left axis will be Tier 1 label + overlays
	if (goal.metricScale && secondaryGoal)
		return `${goal.playerPlanMetric.label} & ${
			goal.playerPlanMetric.metricType.value === HIT ? HIT_OVERLAY_LABEL : PITCH_OVERLAY_LABEL
		}`;
	// Otherwise, left axis will be some combination of Tier 1 & Tier 2
	else if (goal.playerPlanMetric.isQualitative) return secondaryGoal?.playerPlanMetric.label ?? "";
	return `${goal.playerPlanMetric.label}${secondaryGoal ? ` & ${secondaryGoal.playerPlanMetric.label}` : ""}`;
};

export const getRightAxisLabel = (goal: TPlayerPlanGoal, secondaryGoal?: TPlayerPlanGoal): string => {
	// If primary goal has a metric scale, right axis will be just the Tier 2 label
	if (goal.metricScale && secondaryGoal) return secondaryGoal.playerPlanMetric.label;
	// Otherwise, the right axis will be the overlay label
	return goal.playerPlanMetric.metricType.value === HIT ? HIT_OVERLAY_LABEL : PITCH_OVERLAY_LABEL;
};

export const getAxisRange = (scaleLabel: TPlayerPlanMetricScaleLabel): number[] | null => {
	return scaleLabel === LABEL_20_80 ? AXIS_RANGE_20_80 : null;
};

// Gets axis tick0 based on scale label
export const getAxisTick0 = (scaleLabel: TPlayerPlanMetricScaleLabel): number | null => {
	return scaleLabel === LABEL_20_80 ? TICK0_20_80 : null;
};

// Gets axis tick format based on scaleLabel, metric format
export const getAxisTickFormat = (scaleLabel: TPlayerPlanMetricScaleLabel, format: string | null): string | null => {
	return format === THREE_DECIMAL_FORMAT ? TICKFORMAT_THREE_DECIMAL : null;
};

// Gets axis dTick based on scaleLabel
export const getAxisDTick = (scaleLabel: TPlayerPlanMetricScaleLabel): number | null => {
	return scaleLabel === LABEL_20_80 ? DTICK_20_80 : null;
};

// Creates split label string for goal metric, and populates two arrays consisting of split values, labels
export const parseSplits = (
	playerPlanSplits: TPlayerPlanGoalSplit[] | undefined,
	splitValues: string[],
	splitLabels: string[]
): string => {
	const filteredSplits = playerPlanSplits?.filter((goalSplit: TPlayerPlanGoalSplit) => !goalSplit.isDeleted);
	if (filteredSplits)
		filteredSplits.forEach((split: TPlayerPlanGoalSplit) => {
			splitValues.push(split.playerPlanSplit.value);
			splitLabels.push(split.playerPlanSplit.label);
		});
	return `${
		filteredSplits?.length
			? `${filteredSplits
					.map((goalSplit: TPlayerPlanGoalSplit) => goalSplit.playerPlanSplit?.label)
					.sort()
					.join(", ")}`
			: ""
	}`;
};

// Use splitLks and the arrays populated by parseSplits() to get the split label string for the overlays
export const parseOverlaySplits = (splitLks: string | null, splitValues: string[], splitLabels: string[]): string => {
	if (splitLks == null) return "";

	const overlaySplitLabels: string[] = [];
	const overlaySplitValues: string[] = splitLks.split(",");
	overlaySplitValues.forEach((overlaySplitValue: string) => {
		const index = splitValues.findIndex(value => value === overlaySplitValue);
		if (index !== -1) {
			overlaySplitLabels.push(splitLabels[index]);
		}
	});
	return `${overlaySplitLabels?.length ? `${overlaySplitLabels.sort().join(", ")}` : ""}`;
};

// Used to filter out deleted FAs and SAs that are created within a 24 hour window
export const filterFastDeletes = (startDate: string, endDate: string): boolean => {
	if (startDate && endDate) {
		const createDate = dayjs(startDate);
		const lastChangeDate = dayjs(endDate);
		const hours = lastChangeDate.diff(createDate, "hours");
		return hours > 24;
	}

	return true;
};

// Used to prepare the secondary goal form for adding just a tier 2 goal
export const prepareSecondaryGoal = (
	setAddGoalForm: (value: TGoalForm) => void,
	modalPrimaryGoal: TPlayerPlanGoal,
	addGoalForm: TGoalForm
) => {
	const goal = {
		primaryGoalId: modalPrimaryGoal.id,
		primaryGoalStatus: modalPrimaryGoal.status.value,
		parentGoalId: null,
		direction: addGoalForm.secondaryGoal?.direction,
		metric: addGoalForm.secondaryGoal?.metric,
		target: addGoalForm.secondaryGoal?.target,
		description: addGoalForm.secondaryGoal?.description,
		secondaryGoal: null,
		splits: modalPrimaryGoal.playerPlanSplits.map((split: TPlayerPlanGoalSplit) => {
			return split.playerPlanSplit.value;
		}),
		drills: null,
		needsApproval: null,
		isStrength: modalPrimaryGoal.isStrength,
		isPriority: modalPrimaryGoal.isPriority,
		isLead: modalPrimaryGoal.isLead
	} as TGoalForm;

	setAddGoalForm(goal);
};
