// TODO move all of these the be database driven

export const TOOLTIP_SURPLUS_VALUE =
	"Surplus Value (SV) is a forecast of the player's expected production minus his expected compensation above the league minimum in each season. Expected production is inclusive of projected performance over the period of contractual control, optionality, and the balance of downside risk and upside potential. Player compensation is either a guaranteed contract or projected compensation in his team control years.";
export const TOOLTIP_SP_RAR =
	"Projected runs above replacement level as a starting pitcher for the current season, per full season (700 PAs). Replacement level is the talent threshold at which pitchers capable of pitching in a starting or bulk role are freely available on the open market (via waivers, minor league free agency, or league minimum major league deals). Our projections attempt to estimate a player's talent level relative to that replacement level benchmark. For pitchers, higher RAR values correspond to better run prevention.";
export const TOOLTIP_RP_RAR =
	"Projected runs above replacement level as a reliever for the current season, per full season (250 PAs/60 IP). Replacement level is the talent threshold at which relief pitchers are freely available on the open market (via waivers, minor league free agency, or league minimum major league deals). Our projections attempt to estimate a player's talent level relative to that replacement level benchmark.";
export const TOOLTIP_POSITION_PLAYER_RAR =
	"Projected runs above replacement level for the current season, per full season (600 PAs). Replacement level is the talent threshold at which players are freely available on the open market (via waivers, minor league free agency, or league minimum major league deals).\n\nOur projections attempt to estimate a player's talent level relative to that replacement level benchmark, including offense (hitting + baserunning) and defense (position and fielding ability at those positions).\n\nWe also include a run value adjustment specific to the position played, defined by the offensive expectations at that position. This serves as a proxy for the relative scarcity of that position on the open market. Higher positional adjustments imply that it is harder to find a player at that position at any given offensive talent level and that we should place additional value on them relative to someone with the same projected performance at a different position.";

export const TOOLTIP_ROSTER_SALARY =
	"Average projected contract cost when the player is on the active roster, assuming that the player is projected well enough to be on the active roster.";
export const TOOLTIP_SEASON_VALUE = "Projected value in dollars of the player’s performance in a given season.";
export const TOOLTIP_SURPLUS_VALUE_YEARLY =
	"Surplus Value (SV) is a forecast of the player's expected production minus his expected compensation above the league minimum in each season.\n\nA player may occasionally receive positive surplus value after his team controlled years due to the potential for compensation for a rejected qualifying offer.";
export const TOOLTIP_ROSTER_PERC = "Projected likelihood player receives MLB playing time during season.";
export const TOOLTIP_MINORS_FORTY_MAN_PERC = "Projected likelihood player spends season on minor league option";
export const TOOLTIP_NON_TENDER_PERC = "Projected likelihood player is non-tendered prior to season.";
export const TOOLTIP_CLUB_DECLINED_PERC = "Projected likelihood of club option being declined prior to season.";
export const TOOLTIP_PLAYER_DECLINED_PERC = "Projected likelihood of player option being declined prior to season.";
export const TOOLTIP_QO_COMP_PERC =
	"Projected likelihood of club receiving compensation for rejected qualifying offer prior to season.";
export const TOOLTIP_MJ_REPLACEMENT_PERC =
	"Projected likelihood that a player on a guaranteed major league contract is removed from the roster due to a decline in projected talent.";
export const TOOLTIP_OFF_ROSTER_PERC =
	"Projected likelihood that a player is no longer on the roster for reasons not covered in the other columns, such as 6-year free agency, a previous non-tender or release, or any other expiry of team control.";

export const TOOLTIP_PROJ_SURPLUS_VALUE =
	"Surplus value based on a player’s projection.\n\nOur projections models better predict performance for players with more major league experience, and we therefore place more weight on this value as a player accumulates service time.";
export const TOOLTIP_PRO_MODEL_SURPLUS_VALUE =
	"Surplus value from a player’s prospect model score.\n\nWe have constructed the prospect model to better predict major league value / performance for minor league players with little to no major league experience, and we place more weight on this value for these players relative to their projections.";
export const TOOLTIP_PRO_MODEL_WEIGHT =
	"Weight placed on the prospect model when calculating the blended surplus value.\n\nWe have two core player evaluation model paradigms: projections and prospect scores. Since these two paradigms perform better for different player populations (experienced major league players and younger prospects, respectively), we blend the two values for players who have between 0 and 1 year of major league service, with increasing weight on the projections model as MLS increases.";
export const TOOLTIP_RAA = "Runs above average";
export const TOOLTIP_XWAR =
	"The expected wins above replacement (WAR) for a prospect over their club control years. This is not conditional on reaching the Major Leagues and is an estimate of a prospect's value to their club. xWAR is the main input for prospects within the Surplus Value framework.";
export const TOOLTIP_ML_PERC = "Projected probability that a player makes the majors.";

export const TOOLTIP_RA9_SCALE = "Expected runs allowed per 9 innings. Major League average is 4.50.";
export const TOOLTIP_20_80_SCALE = "50 is major league average.";
export const TOOLTIP_STUFF =
	"Estimated quality of a pitch based on its velocity, movement, and release point, but not its location. Stuff grades are presented on two scales: Expected runs allowed per 9 innings where 4.50 is a league average pitch across all pitch types (RA9) and the 20-80 scale, where 50 is major league average for that pitch type.";
export const TOOLTIP_STUFF_GRADE =
	"Estimated quality of a pitch based on its velocity, movement, and release point, but not its location. Values are reported on the 20-80 scale, where 50 is MLB average for that pitch type.";
export const TOOLTIP_STUFF_RA9 =
	"Estimated quality of a pitch based on its velocity, movement, and release point, but not its location. Values are reported on the RA9 scale, where MLB average is 4.50. Average secondaries tend to get better results than average fastballs, and as a result, a slider with a 50 Stuff grade will have a better Stuff RA9 than a fastball with a 50 Stuff grade.";
export const TOOLTIP_LOCATION =
	"Estimated quality of a pitch based on its location when it crosses the plate. Locations grades reward pitchers for throwing pitches in the zone before two strikes, throwing enticing chase pitches with two strikes, and throwing their offerings to specific areas of the strike zone that make their pitches play up based on their velocity, movement, and release point. Location grades are presented on two scales: Expected runs allowed per 9 innings where 4.50 is indicative of league average pitch locations on that pitch (RA9) and the 20-80 scale, where 50 is major league average locations on that pitch.";
export const TOOLTIP_LOCATION_GRADE =
	"Estimated quality of a pitch based on its location. Values are reported on the 20-80 scale, where 50 is MLB average for that pitch type.";
export const TOOLTIP_LOCATION_RA9 =
	"Estimated quality of a pitch based on its location. Values are reported on the RA9 scale, where MLB average is 4.50.";
export const TOOLTIP_INTRINSIC_GRADE =
	"Estimated quality of a pitch based on its velocity, movement, release point, and location. Values are reported on the 20-80 scale, where 50 is MLB average for that pitch type.";
export const TOOLTIP_INTRINSIC_RA9 =
	"Estimated quality of a pitch based on its velocity, movement, release point, and location. Values are reported on the RA9 scale, where MLB average is 4.50. Average secondaries tend to get better results than average fastballs, and as a result, a slider with a 50 Intrinsic grade will have a better Intrinsic RA9 than a fastball with a 50 Intrinsic grade.";

export const TOOLTIP_AMA_PROSPECT_VALUE =
	"Amateur Surplus Value (AMA SV) is a forecast of the player's expected production at the Major League level relative to expected compensation over his team control years. Due to the long and uncertain time-horizon for amateur players to realize surplus value, the amateur model predicts an expectation of production in units of wins above replacement over the full controllable period (xWAR). A separate mapping approximates expected compensation by converting xWAR to AMA SV.";

// Below is specifically for `full_season_scaled_oaa_centered`
export const TOOLTIP_OAA =
	"Outs above average. Each ball in play is evaluated for the probability that a league average fielder would convert that play into an out, and we credit fielders for overperforming or underperforming that expectation. The value displayed is scaled to a full season of opportunities, and a league average fielder would have 0 OAA. OAA can take a meaningful amount of time to stabilize, so you may see large full-season-scaled numbers for players with a low number of opportunities. We have found that once a player has approximately a third of a season’s worth of opportunities (varies by position but can use 100 opportunities as a rule of thumb), OAA is relatively stable.";
export const TOOLTIP_BAT_SPEED_INFERRED =
	"INFERRED\nSpeed of the bat at contact, inferred using hitters’ hardest-hit balls. Bat speed is inferred as a linear function of the hitter’s top-12.5% exit velocities, and converted to the 20-80 scale, where 50 is major league average. Bat speed has the highest correlation with in-season batting performance of our batting submodels.";
export const TOOLTIP_BAT_SPEED_MEASURED =
	"Speed of the bat at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. Bat speed is converted to the 20-80 scale, where 50 is major league average. Bat speed has the highest correlation with in-season batting performance of our batting submodels.";
export const TOOLTIP_BAT_TO_BALL =
	"An estimate of a hitter’s ability to make contact, inferred from his smash factor relative to expectation. It measures his ability to make flush contact after controlling for count, location, pitch quality, and bat speed, converted to the 20-80 scale, where 50 is major league average.";
export const TOOLTIP_SWING_DECISIONS =
	"An estimate of a hitter’s ability to make the correct decision (swing or take) for a given pitch depending on count, pitch quality, hitter talent, and his personal hot/cold zones, converted to the 20-80 scale, where 50 is major league average.";
export const TOOLTIP_COMPOSITE_HITTER_GRADE =
	"A weighted average of a hitter's Bat Speed, Bat to Ball, and Swing Decisions, with the weights determined by each grade's relationship to overall production. Research suggests that Bat Speed is most valuable, followed by Bat to Ball, then Swing Decisions. This is converted to the 20-80 scale, where 50 is major league average.";
export const TOOLTIP_ATTACK_ANGLE_INFERRED =
	"INFERRED\nAn estimate of the angle at which the bat is moving relative to the ground at contact. A high attack angle indicates that the batter makes harder contact when hitting the ball in the air, while a low attack angle indicates that the batter makes harder contact when hitting the ball on a line or on the ground. Attack angles are reported in degrees, where an attack angle of 0 means that the bat is moving parallel to the ground when contact is made, and higher, positive attack angles mean the bat is moving upward through the zone. We infer attack angle from a batter’s batted ball profile, particularly the launch angle of his top-12.5% exit velocities.";
export const TOOLTIP_ATTACK_ANGLE_MEASURED =
	"The angle at which the bat is moving relative to the ground at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. A high attack angle suggests that the batter likely makes harder contact while hitting the ball in the air, while a low attack angle suggests that the batter likely makes harder contact when hitting the ball on a line or on the ground. Attack angles are reported in degrees, where an attack angle of 0 means that the bat is moving parallel to the ground when contact is made, and higher, positive attack angles mean the bat is moving upward through the zone.";
export const TOOLTIP_SPRINT_SPEED =
	"An estimate of the sprint speed of the player, including tracking (HawkEye/StatCast) data, scouting reports, and aging effects, reported on the 20-80 scale, where 50 is major league average.";

export const TOOLTIP_POS_PLAYER_STRIKEOUT_RATE =
	"Strikeout rate, defined as strikeouts divided by plate appearances. MLB average K% is roughly 22% in 2024.";
export const TOOLTIP_POS_PLAYER_WALK_RATE =
	"Walk rate, defined as walks divided by plate appearances. MLB average BB% is roughly 8% in 2024.";
export const TOOLTIP_EV_XWOBA =
	"An estimate of a hitter's production that considers his strikeout rate, walk rate, and the exit velocity of his balls in play. Average xwOBA is scaled to match MLB average OBP, which is roughly .310 in 2024.";
export const TOOLTIP_EVLA_XWOBA =
	"An estimate of a hitter's production that considers his strikeout rate, walk rate, and the exit velocity and launch angle of his balls in play. A hitter's EVLA xwOBA may exceed his EV xwOBA if he consistently hits line drives to maximize hit probability, while his EVLA xwOBA may fall short of his EV xwOBA if he consistently hits grounders or flyballs. Average xwOBA is scaled to match MLB average OBP, which is roughly .310 in 2024.";
export const TOOLTIP_EVLAHLA_XWOBA =
	"An estimate of a hitter's production that considers his strikeout rate, walk rate, and the exit velocity, launch angle, and horizontal launch angle of his balls in play. A hitter's EVLAHLA xwOBA may exceed his EVLA xwOBA if he consistently hits balls to his pull side to maximize extra base hit probability, while his EVLA xwOBA may fall short of his EV xwOBA if he consistently hits balls to the center of the field. Average xwOBA is scaled to match MLB average OBP, which is roughly .310 in 2024.";
export const TOOLTIP_WOBA =
	"A measure of a hitter's value based on his observed outcomes, with the value of each outcome determined by how many runs it typically creates. A hitter's wOBA may exceed his xwOBA if he has high sprint speed, plays in a hitter-friendly environment, or experiences good fortune on batted balls. MLB average wOBA is scaled to match MLB average OBP, which is roughly .310 in 2024.";
export const TOOLTIP_PRC_PLUS =
	"A measure of a hitter's value based on his observed outcomes, with the value of each outcome determined by how many runs it typically adds. pRC+ controls for park effects and run environments, and it is scaled such that 100 is league average and every point above 100 represents a percentage point better than league average.";

export const TOOLTIP_SWING_METRICS_BAT_SPEED =
	"The speed of the bat at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. MLB average bat speed is roughly 74 MPH.";
export const TOOLTIP_SWING_METRICS_VERTICAL_ATTACK_ANGLE =
	"Vertical attack angle, the angle at which the bat is moving relative to the ground at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. MLB average vertical attack angle is roughly 7 degrees, with an angle of 0 meaning that the bat is moving parallel to the ground when contact is made, and higher, positive angles meaning that the bat is moving upward through the zone.";
export const TOOLTIP_HORIZONTAL_ATTACK_ANGLE =
	"Horizontal attack angle, the angle at which the bat is moving relative to the centerline connecting the rubber and the plate at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. MLB average horizontal attack angle is roughly -1.5 degrees, with an angle of 0 meaning that the bat is moving directly towards center field when contact is made, and higher, positive angles meaning that the bat is moving toward the pull side.";
export const TOOLTIP_VERTICAL_BAT_ANGLE =
	"Vertical bat angle, the angle of the bat relative to the horizon at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. MLB average vertical bat angle is roughly -29 degrees, with an angle of 0 meaning that the bat is parallel to the horizon and more negative angles meaning that the bat is pointing towards the ground.";
export const TOOLTIP_HORIZONTAL_BAT_ANGLE =
	"Horizontal bat angle, the angle of the bat relative to a line parallel to the front of home plate at contact (or at the point of theoretical contact, in the case of whiffs), as measured by stadium tracking units. MLB average horizontal bat angle is roughly 5 degrees, with an angle of 0 meaning that the bat is pointing to the opposite batter's box and more positive angles meaning that the bat is pointing towards the field of play.";

export const TOOLTIP_ERA_MINUS =
	"Earned run average, adjusted for park and league such that 100 is league average and each point below 100 is a percentage point better than league average.";
export const TOOLTIP_XFIP_MINUS =
	"Expected fielding independent pitching, adjusted for park and league such that 100 is league average and each point below 100 is a percentage point better than league average. xFIP- estimates ERA- based on strikeouts, walks, hit by pitches, and fly balls allowed, assuming a league average home run per flyball rate.";

export const TOOLTIP_ACTUAL_PITCHER_STRIKEOUT_RATE =
	"Strikeout rate, defined as strikeouts divided by batters faced. MLB average K% is roughly 22% in 2024.";
export const TOOLTIP_EXPECTED_PITCHER_STRIKEOUT_RATE =
	"Expected strikeout rate, estimated using the velocity, movement, and location of a pitcher's entire arsenal, along with his pitch usage rates across counts. MLB average xK% is roughly 22% in 2024.";
export const TOOLTIP_ACTUAL_PITCHER_WALK_RATE =
	"Walk rate, defined as walks divided by batters faced. MLB average BB% is roughly 8% in 2024.";
export const TOOLTIP_EXPECTED_PITCHER_WALK_RATE =
	"Expected walk rate, estimated using the velocity, movement, and location of a pitcher's entire arsenal, along with his pitch usage rates across counts. MLB average xBB% is roughly 8% in 2024.";
export const TOOLTIP_ACTUAL_GROUNDBALL_RATE =
	"Groundball rate, defined as groundballs divided by batted balls. MLB average GB% is roughly 43% in 2024.";
export const TOOLTIP_EXPECTED_GROUNDBALL_RATE =
	"Expected groundball rate, estimated using the velocity, movement, and location of a pitcher's entire arsenal, along with his pitch usage rates across counts. MLB average xGB% is roughly 43% in 2024.";
export const TOOLTIP_ACTUAL_HOME_RUN_RATE =
	"Home run rate, defined as home runs divided by batted balls. MLB average HR% is roughly 4% in 2024.";
export const TOOLTIP_EXPECTED_HOME_RUN_RATE =
	"Expected home run rate, estimated using the velocity, movement, and location of a pitcher's entire arsenal, along with his pitch usage rates across counts. MLB average xHR% is roughly 4% in 2024.";
export const TOOLTIP_ACTUAL_WOBACON =
	"A measure of the value allowed by pitchers on batted balls based on his observed outcomes, with the value of each outcome determined by how many runs it typically creates. MLB average wOBAcon is roughly .360 in 2024.";
export const TOOLTIP_EXPECTED_WOBACON =
	"A measure of the value allowed by pitchers on batted balls based on his expected outcomes, estimated using exit velocity and launch angle. A pitcher's wOBAcon allowed may exceed his expected wOBAcon allowed if he pitches in a hitter-friendly environment or experiences poor fortune on batted balls. MLB average xwOBAcon is roughly .360 in 2024.";

export const TOOLTIP_ACTUAL_SWING_RATE =
	"Swing rate, defined as swings divided by pitches. MLB average Swing% is roughly 48% in 2024.";
export const TOOLTIP_EXPECTED_SWING_RATE =
	"Expected swing rate, estimated using pitches' velocity, movement, location, and the counts in which they were thrown. MLB average xSwing% is roughly 48% in 2024.";
export const TOOLTIP_ACTUAL_WHIFF_RATE =
	"Swinging strike rate, defined as swings and misses divided by pitches. MLB average SwStr% is roughly 12% in 2024.";
export const TOOLTIP_EXPECTED_WHIFF_RATE =
	"Expected swinging strike rate, estimated using pitches' velocity, movement, location, and the counts in which they were thrown. MLB average xSwStr% is roughly 12% in 2024.";
export const TOOLTIP_ACTUAL_CHASE_RATE =
	"Chase rate, defined as out of zone swings divided by out of zone pitches. MLB average Chase% is roughly 28% in 2024.";
export const TOOLTIP_EXPECTED_CHASE_RATE =
	"Expected chase rate, estimated using pitches' velocity, movement, location, and the counts in which they were thrown. MLB average xChase% is roughly 28% in 2024.";
export const TOOLTIP_ACTUAL_CSW_RATE =
	"Called and swinging strike rate, defined as called strikes and swings and misses divided by pitches. MLB average CSW% is roughly 29% in 2024.";
export const TOOLTIP_EXPECTED_CSW_RATE =
	"Expected called and swinging strike rate, estimated using pitches' velocity, movement, location, and the counts in which they were thrown. MLB average xCSW% is roughly 29% in 2024.";

// INTL

export const TOOLTIP_INTL_PROSPECT_VALUE =
	"International Surplus Value (INTL SV) is a forecast of an INTL amateur player’s expected production at the major league level relative to expected compensation over his team controlled years. Due to the long and uncertain time-horizon for international players to realize surplus value, the international model predicts an expectation of production in units of wins above replacement over the full controllable period (xWAR). A separate mapping approximates expected compensation by converting xWAR to INTL SV.\n\nINTL SV is only for INTL Amateurs; some INTL PROs show up with values but those should be disregarded. We are working to remove these values.";
export const TOOLTIP_INTL_ELIGIBLE_AGE =
	"Eligible age is determined as age on August 31st of a player’s eligible year. In cases where a birthdate is missing, the default eligible age will be set to 18.0.";
export const TOOLTIP_INTL_OBSERVED =
	"Observed grades for international amateurs are expected to trend significantly lower than professionals, primarily due to the age and distance from majors of the population. Sample sizes for this group tend to be smaller compared to those at the professional or domestic amateur level due to the less structured manner of data collection and includes data from scrimmages and workouts so the level of intent may not necessarily be the same as professional and domestic amateur in-game data.";
export const TOOLTIP_INTL_REPORT_DATE_RANGE =
	"Report Date Range defines the timeframe during which scouting reports are included in the international model. If multiple reports from the same scout fall within this period, only the most recent report is utilized.";
export const TOOLTIP_INTL_PROSPECT_VALUE_RANK =
	"Rank indicates a player’s historical surplus value ranking, categorized by player type (position player or pitcher).";
